<div class="editor">
  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" >
  </ngx-editor-menu>
  <ngx-editor [editor]="editor"
              [placeholder]="placeholder"
              outputFormat="html" [formControl]="editorFormControl" >
  </ngx-editor>
  <ng-container *ngIf="maxContentLength">
    <mat-error *ngIf="checkContentLength(editorFormControl.value)" >
      <i class="fas fa-exclamation-circle"></i>
      {{ 'Must contain ' + maxContentLength + ' characters maximum'}}
    </mat-error>
  </ng-container>
</div>
