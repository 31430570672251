import {
  ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output,
} from '@angular/core';
import { RoundSportEnum } from 'src/app/common/Enums/RoundSportEnum';
import { RoundTypesEnum } from 'src/app/common/Enums/RoundTypesEnum';
import { CreateRoundMainDetailsFormInterface } from 'src/app/common/FormsModels/create-round-main-details-form-interface';
import { SelectOption } from 'src/app/common/components/_base-component/select/select.component';
import { roundTypes } from 'src/app/common/models/RoundTypeModel';
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { Observable, map, startWith, tap, Subject } from 'rxjs';
import { RoundHelperService } from '../../round-helper.service';
import { RoundStatusEnum } from 'src/app/common/Enums/RoundStatusEnum';
import { APP_DATA } from "../../../general.app.config";
import { SnackBarService } from "../../../core/services/snack-bar.service";
import { TooltipPositionEnum } from "../../../common/Enums/TooltipPositionEnum";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'details-section',
  templateUrl: './details-section.component.html',
  styleUrls: ['./details-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsSectionComponent implements OnInit, OnDestroy {

  @Output() onOpenEditCloseDate = new EventEmitter();

  roundDetailsForm: FormGroup<CreateRoundMainDetailsFormInterface>;

  typeOptionList: SelectOption[] = roundTypes
    .filter(type => !type.beVariable.toLowerCase().includes('streak'))
    .map(item => ({
      value: item.beVariable,
      label: item.oneRoundSportTypeLabel
    }));

  sportTypes = Object.keys(RoundSportEnum)
    .map(item => ({
      value: item,
      label: item
    }));

  isRoundClosed$ = this.roundHelperService.roundStatus$.pipe(
    map(roundStatus => roundStatus === RoundStatusEnum.CLOSED)
  )

  isCategory$: Observable<boolean>;

  tooltipsTexts = {
    name: 'The title of the game displayed to users',
    description: 'Text describing the round, how to play and what can be won',
    openDate: 'The time and date the round will appear open for entries',
    closeDate: 'The time and date the round will close for entries',
    type: 'If the round content is related to sport/politics or other',
    backgroundImage: 'We recommend using a 1000px x 262px image resolution (minimum). Max image size is 4MB',
    sport: 'Select one option that best categorizes the content of the round',
    imageForMultiRound: 'We recommend using a 60px x 60px image resolution. Max image size is 4MB'
  };

  private unsubscribe$: Subject<void> = new Subject();

  protected readonly appData = APP_DATA;

  protected readonly TooltipPositionEnum = TooltipPositionEnum;

  constructor (
    private rootFormGroup: FormGroupDirective,
    public roundHelperService: RoundHelperService,
    private snackbarService: SnackBarService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.roundDetailsForm = this.rootFormGroup.control;


    this.roundDetailsForm
      .get('type').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        startWith(this.roundDetailsForm.get('type').value),
        tap((type: RoundTypesEnum) => this.roundHelperService.roundType = type),
      ).subscribe();

    this.isCategory$ = this.roundDetailsForm
      ?.get('type')
      ?.valueChanges.pipe(
        startWith(this.roundDetailsForm.get('type').value),
        map(type => type === RoundTypesEnum.Sport));
  }

  prevent(e) {
    e.stopPropagation();
  }

  uploadRoundImage(image: {id: number, url: string} | null) {
    if (image) {
      this.roundDetailsForm.get('imageUrl').patchValue(image.url);
    } else {
      this.roundDetailsForm.get('imageUrl').patchValue(null);
    }
    event.stopPropagation();
  }


  showErrorMessage(event: any) {
    if (event) {
      this.snackbarService.showSnackBar(this.appData.BIG_IMAGE, true)
    }
  }
}
