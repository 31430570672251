import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NGX_MAT_DATE_FORMATS, NgxMatDateAdapter } from "@angular-material-components/datetime-picker";
import { NgxMatMomentAdapter } from "@angular-material-components/moment-adapter";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map, merge,
  of,
  Subject, switchMap,
  takeUntil,
  throwError
} from "rxjs";
import { APP_DATA } from "../../../../general.app.config";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "../../../../core/services/snack-bar.service";
import { closeDateValidator } from '../../../modules/validators/close-date-validator';
import { filter, tap } from "rxjs/operators";
import { DialogService } from "../../_base-component/dialog/dialog.service";
import moment from "moment/moment";
import { SelectOption } from "../../_base-component/select/select.component";
import { DatesFormatPipePipe } from "../../../modules/pipes/dates.pipe";
import { DateFormats } from "../../../Enums/date-formats";
import { StreaksService } from "../../../../core/services/streaks.service";
import { IsCloseDateAfterOpenService } from "../../../../core/services/leaderboard-date-validator.service";
import { TooltipPositionEnum } from "../../../Enums/TooltipPositionEnum";
import { CreateTierColorComponent } from "../create-tier-color/create-tier-color.component";
import { Tier } from "../../../models/StreaksCustomLeaderboardModel";
import { RoundsForLeaderboardModel } from "../../../models/CustomLeaderboardDetailsModel";
import { TiersValidatorHelperService } from "../../../../core/services/streak/tiers-validator-helper.service";

export const CUSTOM_MOMENT_FORMATS = {
  parse: {},
  display: {
    dateInput: 'DD MMM YYYY, HH:MM',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'create-streaks-leaderboard',
  templateUrl: './create-streaks-leaderboard.component.html',
  styleUrls: ['./create-streaks-leaderboard.component.scss'],
  providers: [
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
    {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter},
    DatesFormatPipePipe
  ],
})


export class CreateStreaksLeaderboardComponent implements OnInit, OnDestroy {

  leaderboardDetailsForm: FormGroup;

  leaderboardRoundsForm: FormGroup;

  leaderboardStructureForm: FormGroup;

  appData = APP_DATA;

  header = 'Create Leaderboard';

  confirmButtonTooltip = 'Please fill start and end dates to enable this button. Click on this button to load rounds based on start and end dates';

  saveButtonTooltip$ = new BehaviorSubject('Please fill all fields in both tabs to enable this button');

  tiersTooltip = 'Please start tiers creation from the highest one and create them in the descending order';

  dateFormats = DateFormats;

  tabIndex = 0;

  radioIndex = 0;

  currentRounds = [];

  roundsForLeaderBoardData$ = new BehaviorSubject<SelectOption[]>([]);

  rangeRequiredFields = ['minPoints', 'maxPoints', 'prize'];

  exactPointsRequiredFields = ['exactPoints', 'exactPrize'];

  saveButtonState$ = new BehaviorSubject(true);

  //TODO hotfix need to refactor
  tiersToDelete = [];

  protected readonly TooltipPositionEnum = TooltipPositionEnum;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CreateStreaksLeaderboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
    private datesFormatPipePipe: DatesFormatPipePipe,
    private streaksService: StreaksService,
    private isCloseDateAfterOpenService: IsCloseDateAfterOpenService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private tiersValidatorHelperService: TiersValidatorHelperService,
  ) {
  }

  ngOnInit(): void {
    this.tiersValidatorHelperService.isStreak$.next(true);

    this.buildForm();

    if (this.data) {
      this.fillForm();
      this.getRoundsData(true);
    }

    this.handleDatesChange();

    this.handleStructureValuesChange();

    this.handleSaveState();


  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleSaveState() {
    merge(
      this.leaderboardStructureForm.valueChanges,
      this.leaderboardDetailsForm.valueChanges,
      this.leaderboardRoundsForm.valueChanges
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (!this.isFormValid() && this.areDatesDirty()) {
          this.saveButtonTooltip$.next('Please load new rounds to save the leaderboard')
        }
        this.saveButtonState$.next(this.isSaveDisabled());
    });
  }

  handleDatesChange() {
    this.leaderboardDetailsForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.dateErrorChecker(this.leaderboardDetailsForm));
  }

  handleStructureValuesChange() {
    this.leaderboardStructureForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.structureErrorChecker());
  }

  getRoundsData(initRounds = false) {
    this.leaderboardRoundsForm.patchValue({selectedRounds: []});
    const startDate = initRounds ? this.data.startDate : new Date(this.leaderboardDetailsForm.get('startDate').value).toISOString();
    const endDate = initRounds ? this.data.endDate : new Date(this.leaderboardDetailsForm.get('endDate').value).toISOString();
    this.streaksService.getStreaksRoundsList(startDate, endDate).pipe(
      takeUntil(this.unsubscribe$),
      map(rounds => {
        return this.transformRoundsData(rounds);
      }),
      tap(data => {
        this.roundsForLeaderBoardData$.next(data);
        if (initRounds) {
          this.leaderboardRoundsForm.patchValue({selectedRounds: this.data.roundIds});
        }
        this.leaderboardDetailsForm.get('startDate').markAsUntouched();
        this.leaderboardDetailsForm.get('startDate').markAsPristine();
        this.leaderboardDetailsForm.get('endDate').markAsUntouched();
        this.leaderboardDetailsForm.get('endDate').markAsPristine();
      })
    ).subscribe();
  }

  transformRoundsData(rounds: RoundsForLeaderboardModel[]) {
    return rounds.map(({ id, streakRound, name, openDate, closeDate }: RoundsForLeaderboardModel) => ({
      value: id,
      label: `${streakRound ? `(${streakRound.id}) ${streakRound.name} ` : ''}(${id}) ${name}, start date: ${this.datesFormatPipePipe.transform(openDate, this.dateFormats.DD_MMMM_YYYY_hh_mmtz)}, end date: ${this.datesFormatPipePipe.transform(closeDate, this.dateFormats.DD_MMMM_YYYY_hh_mmtz)}`,
    }));
  }



  changeValidation(event: any) {
    this.radioIndex = event.value;
    this.tiers.controls.forEach((control) => {
      if (this.radioIndex) {
        this.rangeRequiredFields.forEach(field => {
          control.get(field).removeValidators(Validators.required);
          control.get(field).updateValueAndValidity();
        });

       this.exactPointsRequiredFields.forEach(field => {
         control.get(field).setValidators(Validators.required);
         control.get(field).updateValueAndValidity();
       })

       control.removeValidators(this.tiersValidatorHelperService.maxPointsGreaterThanMinPointsValidator())
      } else {
        this.exactPointsRequiredFields.forEach(field => {
          control.get(field).removeValidators(Validators.required);
          control.get(field).updateValueAndValidity();
        })
        this.rangeRequiredFields.forEach(field => {
          control.get(field).setValidators(Validators.required);
          control.get(field).updateValueAndValidity();
        })
        control.addValidators(this.tiersValidatorHelperService.maxPointsGreaterThanMinPointsValidator())
      }
    })
  }


  buildForm() {
    this.leaderboardDetailsForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      startDate: new FormControl('', Validators.compose([Validators.required])),
      endDate: new FormControl('', [Validators.required, closeDateValidator()]),
    }, {
      validators: [this.isCloseDateAfterOpenService.validateDates()],
    })

    this.leaderboardStructureForm = new FormGroup({
      tiers: this.fb.array([], this.tiersValidatorHelperService.tiersValidator()),
    })


    this.leaderboardRoundsForm = new FormGroup({
      selectedRounds: new FormControl([], [Validators.required])
    })
  }

  get tiers() {
    return this.leaderboardStructureForm.get('tiers') as FormArray;
  }


  openCreateTierPopup() {
    this.dialog.open(CreateTierColorComponent, {
      maxHeight: '300px',
      width:'500px',
      height:'90%',
      panelClass:['prize-builder'],
      autoFocus: false
    }).afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data) {
          this.addTier(data);
          this.changeValidation({value: this.radioIndex});
        }
      })
  }

  addTier(tierBody: {name: string, color: string}) {
    const tier = this.fb.group({
      name: [tierBody.name, Validators.required],
      color: [tierBody.color, Validators.required],
      prize: [],
      exactPrize: [],
      minPoints: [],
      maxPoints: [],
      exactPoints: []
    });
    this.tiers.push(tier);
  }

  addTierWithData(tierBody: Tier) {
    const tier = this.fb.group({
      name: [tierBody.name, Validators.required],
      color: [tierBody.color, Validators.required],
      id: [tierBody.id],
      prize: [this.radioIndex ? null : tierBody.prize],
      exactPrize: [this.radioIndex ? tierBody.prize : null],
      minPoints: [this.radioIndex ? null : tierBody.minPoints],
      maxPoints: [this.radioIndex ? null : tierBody.maxPoints],
      exactPoints: [this.radioIndex ? tierBody.minPoints : null]
    });
    this.tiers.push(tier);
  }

  openEditTierPopup(tier, index) {
    this.dialog.open(CreateTierColorComponent, {
      maxHeight: '300px',
      width:'500px',
      height:'90%',
      panelClass:['prize-builder'],
      autoFocus: false,
      data: tier
    }).afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data) {
          this.tiers.controls[index].patchValue(data);
          this.tiers.controls[index].get('name').markAsTouched()
          this.tiers.controls[index].get('name').markAsDirty()
          this.tiers.controls[index].get('color').markAsTouched()
          this.tiers.controls[index].get('color').markAsDirty()

          this.saveButtonState$.next(this.isSaveDisabled());
        }
      })
  }

  removeTier(index: number) {
    //TODO hotfix for delete tiers need to refactor
    const tierToDeleteIds = this.tiers.at(index).value.id;
    if (tierToDeleteIds) {
      this.tiersToDelete.push(tierToDeleteIds);
    }
    this.leaderboardStructureForm.markAsDirty();
    this.tiers.removeAt(index);
  }

  fillForm() {
    this.leaderboardDetailsForm.get('name').patchValue(this.data.name);
    this.leaderboardDetailsForm.get('startDate').patchValue(this.data.startDate);
    this.leaderboardDetailsForm.get('endDate').patchValue(this.data.endDate);
    if (moment(this.data.endDate).isBefore(Date.now())) {
      this.leaderboardDetailsForm.get('endDate').disable()
    }
    if (this.data.tiers) {
      this.radioIndex = this.data.tiers[0].maxPoints === this.data.tiers[0].minPoints ? 1 : 0;
      this.data.tiers.forEach(tier => {
        this.addTierWithData(tier);
      });
      this.changeValidation({value: this.radioIndex})
    }
    this.header = 'Edit Leaderboard';
    this.saveButtonState$.next(this.isSaveDisabled());
  }

  async closeDialog() {
    if (this.leaderboardDetailsForm.dirty || this.leaderboardRoundsForm.dirty) {
      await lastValueFrom(
        this.dialogService.open(
          {
            dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
            labelOk: 'Yes',
            labelNo: 'No'
          }
        ).pipe(
          filter(response => !!response),
          tap(() => this.dialogRef.close(true))
        )
      )
    } else {
      this.dialogRef.close(true);
    }
  }

  createTiersBody() {
    if (!this.tiers.controls.length) return [];
    return this.tiers.controls
      .filter(control => control.touched)
      .map(control => {
        const tierBody: any = {};
        const id = control.get('id')?.value;
        if (id) {
          tierBody.id = id;
        } else {
          tierBody.name = control.get('name').value;
          tierBody.color = control.get('color').value;
        }

        if (control.get('name').dirty) {
          tierBody.name = control.get('name').value;
        }

        if (control.get('color').dirty) {
          tierBody.name = control.get('name').value;
        }

        if (control.get(this.radioIndex ? 'exactPrize' : 'prize')?.dirty) {
          tierBody.prize = this.radioIndex ? +control.get('exactPrize').value : +control.get('prize').value;
        }
        if (control.get(this.radioIndex ? 'exactPoints' : 'minPoints')?.dirty) {
          tierBody.minPoints = this.radioIndex ? +control.get('exactPoints').value : +control.get('minPoints').value;
        }
        if (control.get(this.radioIndex ? 'exactPoints' : 'maxPoints')?.dirty) {
          tierBody.maxPoints = this.radioIndex ? +control.get('exactPoints').value : +control.get('maxPoints').value;
        }

        return Object.keys(tierBody).length ? tierBody : null;
      })
      .filter(tier => tier)
  }

  saveLeaderboard() {
    const leaderboardBody = this.leaderboardDetailsForm.value;
    const roundsBody = this.leaderboardRoundsForm.value ? this.leaderboardRoundsForm.value.selectedRounds : [];

    if (moment(leaderboardBody.endDate).isBefore(Date.now())) {
      delete leaderboardBody.endDate;
    }

    if (roundsBody.length) {
      leaderboardBody['roundIds'] = roundsBody;
    }

    const tiers = this.createTiersBody();
    const newTiers = tiers.filter(tier => !tier?.id);
    const editedTiers = tiers.filter(tier => tier?.id);

    if (newTiers && newTiers.length && !this.data) {
      leaderboardBody['tiers'] = newTiers;
    }

    const request$ = this.data ?
      this.streaksService.editStreakCustomLeaderboard(leaderboardBody, this.data.id) :
      this.streaksService.createStreakCustomLeaderboard(leaderboardBody);

    request$.pipe(
      switchMap(() => {
        return (this.data && newTiers.length) ? this.streaksService.createSpecialCustomLeaderboardTiers(newTiers, this.data.id) : of(null);
      }),
      switchMap(() => {
        return this.data && (editedTiers.length || this.tiersToDelete.length) ? this.streaksService.editStreakLeaderboardTiers(editedTiers, this.tiersToDelete, this.data.id) : of(null);
      }),
      takeUntil(this.unsubscribe$),
      catchError((error) => {
        if (error.error) {
          this.snackBarService.showSnackBar(error.error.message, true)
        }
        return throwError(error)
      })
    )
      .subscribe(() => {
        this.streaksService.needUpdateCustomLeaderboards$.next(true);
        this.dialogRef.close(true);
      })
  }

  structureErrorChecker() {
    this.tiers.controls.forEach(control => {
      if (control.errors?.maxPointsGreaterThanMinPoints) {
        control.get('maxPoints').setErrors({'maxPointsGreaterThanMinPoints': true})
        control.get('maxPoints').markAsTouched();
        control.get('minPoints').markAsTouched();
      }
    })

  }

    dateErrorChecker(form: FormGroup) {
    if (form.errors?.datesOrderWrong) {
       form.controls['endDate'].setErrors({'incorrect': true});
       form.controls['startDate'].setErrors({'incorrectStartDate': true});
       form.controls['startDate'].markAsTouched();
       form.controls['endDate'].markAsTouched();
    } else {
      const openDateError = form.controls['startDate'].errors;
      const closeDateError = form.controls['endDate'].errors;
      if (closeDateError && closeDateError['incorrect']) {
        delete closeDateError['incorrect'];
        form.controls['endDate'].patchValue(form.controls['endDate'].value);
      }

      if (openDateError && openDateError['incorrectStartDate']) {
        delete openDateError['incorrectStartDate'];
        form.controls['startDate'].patchValue(form.controls['startDate'].value);
      }


      if (openDateError) {
        form.controls['startDate'].setErrors(null);
        form.controls['startDate'].setErrors(openDateError);
      }
      if (closeDateError) {
        form.controls['endDate'].setErrors(null);
        form.controls['endDate'].setErrors(closeDateError);
      }
    }

    if (form.controls['startDate'].value
      && form.controls['endDate'].value
      && !form.errors?.datesOrderWrong
      && !form.controls['startDate'].getError('invalidOpenDate')
      && !form.controls['endDate'].getError('invalidCloseDate')
    ) {
      form.controls['startDate'].setErrors(null);
      form.controls['endDate'].setErrors(null);
    }

    return form;
  }


  areDatesDirty() {
    return (this.leaderboardDetailsForm.get('startDate').dirty || this.leaderboardDetailsForm.get('endDate').dirty);
  }


  isSaveDisabled() {
    if (!(this.leaderboardStructureForm.dirty || this.leaderboardDetailsForm.dirty || this.leaderboardRoundsForm.dirty)) {
      return true;
    }
    return !(!this.areDatesDirty() && this.leaderboardStructureForm.valid && this.tiers.length && this.leaderboardDetailsForm.valid && this.leaderboardRoundsForm.valid) ;
  }

  isFormValid() {
    if (!(this.leaderboardStructureForm.dirty || this.leaderboardDetailsForm.dirty || this.leaderboardRoundsForm.dirty)) {
      return true;
    }
    return !(this.leaderboardStructureForm.valid && this.tiers.length && this.leaderboardDetailsForm.valid && this.leaderboardRoundsForm.valid) ;
  }

  isConfirmDisabled() {
    return !((this.leaderboardDetailsForm.get('startDate').valid && this.leaderboardDetailsForm.get('endDate').valid) && (this.leaderboardDetailsForm.get('startDate').touched || this.leaderboardDetailsForm.get('endDate').touched));
  }
}
