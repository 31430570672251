import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NGX_MAT_DATE_FORMATS, NgxMatDateAdapter } from "@angular-material-components/datetime-picker";
import { NgxMatMomentAdapter } from "@angular-material-components/moment-adapter";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map, of,
  Subject,
  switchMap,
  takeUntil,
  throwError
} from "rxjs";
import { APP_DATA } from "../../../../general.app.config";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "../../../../core/services/snack-bar.service";
import { CustomLeaderboardService } from "../../../../core/services/custom-leaderboard.service";
import { closeDateValidator } from '../../../modules/validators/close-date-validator';
import { filter, tap } from "rxjs/operators";
import { DialogService } from "../../_base-component/dialog/dialog.service";
import moment from "moment/moment";
import { SelectOption } from "../../_base-component/select/select.component";
import { DatesFormatPipePipe } from "../../../modules/pipes/dates.pipe";
import { DateFormats } from "../../../Enums/date-formats";
import { IsCloseDateAfterOpenService } from "../../../../core/services/leaderboard-date-validator.service";

export const CUSTOM_MOMENT_FORMATS = {
  parse: {},
  display: {
    dateInput: 'DD MMM YYYY, HH:MM',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'create-leaderboard',
  templateUrl: './create-leaderboard.component.html',
  styleUrls: ['./create-leaderboard.component.scss'],
  providers: [
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
    {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter},
    DatesFormatPipePipe
  ],
})


export class CreateLeaderboardComponent implements OnInit, OnDestroy {

  leaderboardDetailsForm: FormGroup;

  leaderboardRoundsForm: FormGroup;

  appData = APP_DATA;

  header = 'CREATE A NEW LEADERBOARD';

  dateFormats = DateFormats;

  currentRounds = [];

  roundsForLeaderBoardData$ = new BehaviorSubject<SelectOption[]>([]);

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CreateLeaderboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customLeaderboardService: CustomLeaderboardService,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
    private datesFormatPipePipe: DatesFormatPipePipe,
    private isCloseDateAfterOpenService: IsCloseDateAfterOpenService
  ) {
  }

  ngOnInit(): void {

    this.buildForm();
    if (this.data) {
      this.fillForm();
      this.getRoundsData(this.data.id);
    }
    this.leaderboardDetailsForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.dateErrorChecker(this.leaderboardDetailsForm));


    this.leaderboardRoundsForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      tap(data => {
        let newData = [];
        if(data.selectedRounds.length >= 3) {
          newData = this.roundsForLeaderBoardData$.value.map(item => ({
            ...item,
            disabled: !data.selectedRounds.find(value => value === item.value)
          }));
        } else {
          newData = this.roundsForLeaderBoardData$.value.map(item => ({
            ...item,
            disabled: false
          }));
        }
        this.roundsForLeaderBoardData$.next(newData);
      })
    ).subscribe()

  }

  ngOnDestroy(): void {
    this.customLeaderboardService.customLeaderboardType$.next(null);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleRoundsChange(selectedRounds: any[]) {
    const deselectElements = this.currentRounds.filter(item => !selectedRounds.includes(item));
    const selectElements = selectedRounds.filter(item => !this.currentRounds.includes(item));

    if (deselectElements.length) {
      deselectElements.forEach((item) => {
        this.updateRound(item, false, selectedRounds);
      })
    }
    if (selectElements.length) {
      selectElements.forEach((item) => {
        this.updateRound(item, true, selectedRounds);
      })
    }

    this.leaderboardRoundsForm.markAsPristine();
    this.leaderboardRoundsForm.updateValueAndValidity();
  }

  updateRound(round: any, isSelected: boolean, selectedRounds: any[]) {
    this.customLeaderboardService.saveRounds(round, this.data.id, isSelected)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.currentRounds = selectedRounds;
        }),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error);
        })
      )
      .subscribe();
  }

  saveSelectedRounds() {
    this.handleRoundsChange(this.leaderboardRoundsForm.value.selectedRounds);
  }

  getRoundsData(id: number) {
    this.customLeaderboardService.getRoundsList(id).pipe(
      takeUntil(this.unsubscribe$),
      tap((data) => {
        this.leaderboardRoundsForm.patchValue({selectedRounds:
            data.filter(item => item.selectedForLeaderboard)
              .map(item => item.id)
        }, {emitEvent: false})
        this.currentRounds = this.leaderboardRoundsForm.value.selectedRounds;
      }),
      map(data => {
        return data.map((item) => ({
          value: item.id,
          label: '(' + item.id + ') ' + item.name + ', start date: ' +
            this.datesFormatPipePipe.transform(item.openDate, this.dateFormats.DD_MMMM_YYYY_hh_mmtz) + ', end date: '
            + this.datesFormatPipePipe.transform(item.closeDate, this.dateFormats.DD_MMMM_YYYY_hh_mmtz),
          disabled: data.filter(item => item.selectedForLeaderboard).length >= 3 ? !item.selectedForLeaderboard : false
        }))
      }),
      tap(data => {
        this.roundsForLeaderBoardData$.next(data)
      })
    ).subscribe();
  }



  buildForm() {
    this.leaderboardDetailsForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      startDate: new FormControl('', Validators.compose([Validators.required])),
      endDate: new FormControl('', [Validators.required, closeDateValidator()]),
    }, {
      validators: [this.isCloseDateAfterOpenService.validateDates()],
    })

    this.leaderboardRoundsForm = new FormGroup({
      selectedRounds: new FormControl([], [Validators.required])
    })
  }

  fillForm() {
    this.leaderboardDetailsForm.get('name').patchValue(this.data.name);
    this.leaderboardDetailsForm.get('startDate').patchValue(this.data.startDate);
    this.leaderboardDetailsForm.get('endDate').patchValue(this.data.endDate);
    if (moment(this.data.endDate).isBefore(Date.now())) {
      this.leaderboardDetailsForm.get('endDate').disable()
    }
    this.header = 'EDIT LEADERBOARD';
  }

  async closeDialog() {
    if (this.leaderboardDetailsForm.dirty || this.leaderboardRoundsForm.dirty) {
      await lastValueFrom(
        this.dialogService.open(
          {
            dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
            labelOk: 'Yes',
            labelNo: 'No'
          }
        ).pipe(
          filter(response => !!response),
          tap(() => this.dialogRef.close(true))
        )
      )
    } else {
      this.dialogRef.close(true);
    }
  }

  saveLeaderboard() {
    if (this.leaderboardDetailsForm.invalid) {
      this.leaderboardDetailsForm.markAllAsTouched();
      return;
    }

    const leaderboardBody = this.leaderboardDetailsForm.value;

    if (moment(leaderboardBody.endDate).isBefore(Date.now())) {
      delete leaderboardBody.endDate;
    }
    const request$ = this.data ?
      this.customLeaderboardService.updateCustomLeaderboard(leaderboardBody, this.data.id) :
      this.customLeaderboardService.createNewCustomLeaderboard(leaderboardBody);

    request$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap((data: {id: number}) => {
        if (this.leaderboardDetailsForm.get('startDate').dirty || this.leaderboardDetailsForm.get('endDate').dirty) {
          if (!this.data) {
            this.data = { ...this.leaderboardDetailsForm.getRawValue(), id: data.id};
          }
          return this.customLeaderboardService.getRoundsList(this.data.id).pipe(
            map(data => {
              const selectedRounds = data && data.length ? data.filter(item => item.selectedForLeaderboard)
                  .map(item => item.id) : [];
              this.leaderboardRoundsForm.patchValue({selectedRounds: selectedRounds}, {emitEvent: false})
              this.leaderboardDetailsForm.updateValueAndValidity();
              this.currentRounds = [];
              return data.map((item) => ({
                value: item.id,
                label: '(' + item.id + ') ' + item.name + ', start date: ' +
                  this.datesFormatPipePipe.transform(item.openDate, this.dateFormats.DD_MMMM_YYYY_hh_mmtz) + ', end date: '
                  + this.datesFormatPipePipe.transform(item.closeDate, this.dateFormats.DD_MMMM_YYYY_hh_mmtz),
                disabled: false
              }))
            }),
            tap(data => {
              this.roundsForLeaderBoardData$.next(data);
              this.leaderboardDetailsForm.markAsPristine();
              this.leaderboardDetailsForm.updateValueAndValidity();
            })
          );
        } else {
          return of(true);
        }
      }),
      catchError((error) => {
        this.snackBarService.showSnackBar(error.error.message, true)
        return throwError(error)
      })
    )
      .subscribe(() => {
        this.customLeaderboardService.isNeedUpdateLeaderboardsList$.next(true)
      })
  }


  dateErrorChecker(form: FormGroup) {
    if (form.errors?.datesOrderWrong) {
      form.controls['endDate'].setErrors({'incorrect': true});
      form.controls['startDate'].markAsTouched();
      form.controls['endDate'].markAsTouched();
    } else {
      const openDateError = form.controls['startDate'].errors;
      const closeDateError = form.controls['endDate'].errors;
      if (closeDateError && closeDateError['incorrect']) {
        delete closeDateError['incorrect'];
        form.controls['endDate'].patchValue(form.controls['endDate'].value);
      }

      if (openDateError) {
        form.controls['startDate'].setErrors(null);
        form.controls['startDate'].setErrors(openDateError);
      }
      if (closeDateError) {
        form.controls['endDate'].setErrors(null);
        form.controls['endDate'].setErrors(closeDateError);
      }
    }

    if (form.controls['startDate'].value
      && form.controls['endDate'].value
      && !form.errors?.datesOrderWrong
      && !form.controls['startDate'].getError('invalidOpenDate')
      && !form.controls['endDate'].getError('invalidCloseDate')
    ) {
      form.controls['startDate'].setErrors(null);
      form.controls['endDate'].setErrors(null);
    }

    return form;
  }

}
