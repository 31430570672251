<div class="leaderboard-translation-container">
  <div class="leaderboard-translation-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onClosePrizeWizard()">close</mat-icon>
        <h2 class="popup-header">Leaderboard Translations</h2>
      </div>
      <div class="control-header">
        <button
          mat-raised-button
          [disabled]="!leaderboardDataFormGroup.dirty || leaderboardDataFormGroup.invalid"
          color="primary"
          (click)="saveLocalization()">
          Save
        </button>
      </div>
    </div>
  </div>
  <div class="leaderboard-translation-body">
    <div class="leaderboard-translation-content">
      <div class="leaderboard-translation-details">
        <div class="table-wrapper">
          <div class="table-content">
            <form [formGroup]="leaderboardDataFormGroup">
              <ng-container [formArrayName]="'items'">
                <table
                  mat-table
                  [dataSource]="formArray.controls"
                >
                  <!-- Column name Column -->
                  <ng-container [matColumnDef]="'entityValue'" [sticky]="true">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let itemFormGroup" [formGroup]="itemFormGroup">
                      {{itemFormGroup.get('entityValue').value}}
                    </td>
                  </ng-container>

                  <!-- Text Column -->
                  <ng-container [matColumnDef]="'text'">
                    <th mat-header-cell *matHeaderCellDef>Non<br/>localized</th>
                    <td mat-cell *matCellDef="let itemFormGroup" [formGroup]="itemFormGroup">
                      <div class="leaderboard-text">
                        {{itemFormGroup.get('text').value}}
                      </div>

                    </td>
                  </ng-container>

                  <!-- Locale translate Column -->
                  <ng-container *ngFor="let locale of locales" [matColumnDef]="locale.locale">
                    <th mat-header-cell *matHeaderCellDef>{{ locale.countryName }}</th>
                    <td mat-cell *matCellDef="let itemFormGroup"  [formGroup]="itemFormGroup">
                      <div class="form-field-with-label locale">
                        <mat-form-field appearance="outline">
                          <input
                            placeholder="Enter a translation"
                            matInput
                            trimDoubleSpace
                            trim
                            type="text"
                            [formControlName]="locale.locale">
                        </mat-form-field>
                        <mat-error *ngIf="itemFormGroup.get(locale.locale).hasError('maxlength')">
                          <i class="fas fa-exclamation-circle"></i>
                          <span>{{appData.MAX_LENGHT}}</span>
                        </mat-error>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="getColumns(); sticky:true"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: getColumns();">
                  </tr>
                </table>
              </ng-container>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!(isLoaded$ | async)" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
