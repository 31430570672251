import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UriConfig } from "../../app.config";
import { BehaviorSubject, Observable } from "rxjs";
import {
  InfoPopupTabsCreateModel,
  InfoPopupTabsEditResponseModel,
  InfoPopupTabsModel, InfoTabLocalized
} from "../../common/models/InfoPopupTabsModel";

@Injectable({
  providedIn: 'root'
})
export class InfoPopupCmsService {

  needUpdateContent$ = new BehaviorSubject(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) {}

  getInfoPopupTabs(): Observable<InfoPopupTabsModel[]> {
    return this.http.get<InfoPopupTabsModel[]>(this.uriConfig.infoPopupTabs);
  }

  addInfoPopupTab(tab: InfoPopupTabsCreateModel): Observable<any> {
    return this.http.post<InfoPopupTabsCreateModel>(this.uriConfig.infoPopupTabs, tab);
  }

  localizeTabs(tabs: InfoTabLocalized[]): Observable<any> {
    const body = {
      items: [...tabs]
    }
    return this.http.patch<InfoPopupTabsCreateModel>(this.uriConfig.localizations, body);
  }


  updateInfoPopupTab(tab: InfoPopupTabsCreateModel, id: number): Observable<InfoPopupTabsEditResponseModel[]> {
    return this.http.patch<InfoPopupTabsEditResponseModel[]>(this.uriConfig.infoPopupTabs + '/' + id, tab);
  }

  deleteInfoPopupTab(id: number): Observable<InfoPopupTabsEditResponseModel[]> {
    return this.http.delete<InfoPopupTabsEditResponseModel[]>(this.uriConfig.infoPopupTabs + '/' + id);
  }
}
