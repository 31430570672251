<div class="wrapper" [ngClass]="{'invalid': questionForm.dirty && roundHelperService.roundTryToSave}">
  <div class="prediction-number">{{ predictionNumber }}</div>
  <form [formGroup]="questionForm">
    <div class="header">
      <div *ngIf="questionForm.get('text')">
        <custom-input
          formControlName="text"
          placeholder="Enter question text">
          Text*
        </custom-input>
      </div>

      <div>
        <custom-select
          [ngClass]="{'no-text-question': !questionForm.get('text')}"
          formControlName="type"
          [selectOptions]="questionTypeService.questionTypeSelector$ | async">
          Type*
        </custom-select>
      </div>
    </div>

    <div *ngIf="questionForm.get('imageUrl')">
      <drag-and-drop-file-form
        formControlName="imageUrl"
        [tooltipText]="tooltipTextIcon"
        [url]="questionForm?.get('imageUrl')?.value">
        Icon
      </drag-and-drop-file-form>
    </div>

    <select-create-event
      *ngIf="questionForm?.get('sportEvent')"
      formControlName="sportEvent">
    </select-create-event>
    <div class="group-answers-area">
      <ng-container *ngIf="displatGroupOne$ | async">
        <div class="group-answer">
          <select-create-group
            formControlName="answerGroupId"
            [answerGroupList]="answerGroupList$ | async"
            [questionType]="questionType$ | async"
            (onEditGroup) = "editGroup($event)"
            (onDeleteGroup) = "deleteGroup($event, 'answerGroupId')"
            (onCreateGroup) = "createGroup('answerGroupId')">
          </select-create-group>
          <!-- The two nodes below should not be displayed together. "if else" is not used because the condition is quite cumbersome and difficult to read  -->
          <ng-template
            *ngIf="(
              !questionForm?.get('answerGroupId').value &&
              (questionForm?.get('additional')?.get('answers1st')?.valid ||
              questionForm?.get('additional')?.get('answers')?.valid)
            )"
            [ngTemplateOutlet]="createAnswersGroup"
            [ngTemplateOutletContext]="{
              answersKey: (isListsQuestion$ | async) ? 'answers1st' : 'answers',
              groupKey: 'answerGroupId'
            }">
          </ng-template>
          <ng-template
            *ngIf="(
              questionForm?.get('answerGroupId').value &&
              (questionForm?.get('additional')?.get('answers1st')?.valid ||
              questionForm?.get('additional')?.get('answers')?.valid) &&
              (isAnswersDirty$ | async)
            )"
            [ngTemplateOutlet]="createOrEditAnswersGroup"
            [ngTemplateOutletContext]="{
              answersKey: questionForm?.get('answerGroupId2').value ? 'answers1st' : 'answers',
              groupKey: 'answerGroupId'
            }">
          </ng-template>
        </div>
      </ng-container>
      <ng-container *ngIf="isListsQuestion$ | async">
        <div class="group-answer">
          <select-create-group
            formControlName="answerGroupId2"
            [answerGroupList]="answerGroupList2$ | async"
            [questionType]="questionType$ | async"
            (onEditGroup) = "editGroup($event)"
            (onDeleteGroup) = "deleteGroup($event, 'answerGroupId2')"
            (onCreateGroup) = "createGroup('answerGroupId2')">
          </select-create-group>
          <!-- The two nodes below should not be displayed together. "if else" is not used because the condition is quite cumbersome and difficult to read  -->
          <ng-template
            *ngIf="
              !questionForm?.get('answerGroupId2').value &&
              questionForm?.get('additional')?.get('answers2st')?.valid
            "
            [ngTemplateOutlet]="createAnswersGroup"
            [ngTemplateOutletContext]="{
              answersKey: 'answers2st',
              groupKey: 'answerGroupId2'
            }">
          </ng-template>
          <ng-template
            *ngIf="
              questionForm?.get('answerGroupId2').value &&
              questionForm?.get('additional')?.get('answers2st')?.valid &&
              (isAnswersDirty2st$ | async)
            "
            [ngTemplateOutlet]="createOrEditAnswersGroup"
            [ngTemplateOutletContext]="{
              answersKey: 'answers2st',
              groupKey: 'answerGroupId2'
            }">
          </ng-template>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="questionForm?.get('additional')?.value">
      <ng-container
        [formGroup]="questionForm?.get('additional')"
        *ngIf="(questionType$ | async) === roundTypesEnum.RANGE">
        <div class="number-flex">
          <div>
            <custom-input
              type="number"
              formControlName="minValue"
              placeholder="Enter min value">
              Min Value*
            </custom-input>
          </div>
          <div>
            <custom-input
              type="number"
              formControlName="maxValue"
              placeholder="Enter max value">
              Max Value*
            </custom-input>
          </div>
        </div>
        <div class="number-flex">
          <div>
            <custom-input
              formControlName="displayValue"
              placeholder="Enter display value">
              Display Value*
            </custom-input>
          </div>
          <div>
            <custom-input
              type="number"
              formControlName="incrementValue"
              placeholder="Enter increment value">
              Increment Value*
            </custom-input>
          </div>
        </div>
      </ng-container>

      <ng-container
              [formGroup]="questionForm?.get('additional')"
              *ngIf="(questionType$ | async) === roundTypesEnum.SCORE_PLUS">
          <div>
            <custom-input
                    type="number"
                    formControlName="maxScoreValue"
                    placeholder="Enter max allowed score value">
              Max Score Value*
            </custom-input>
          </div>
      </ng-container>

      <ng-container
        *ngIf="displayAnswers$ | async">
        <answers-question
          [form]="questionForm.controls['additional']"
          [_isExternalEvent]="getExternalEvent()"
          [_questionType]="currentQuestionType$ | async"
          [maxAnswerLength]="(maxAnswerLength$ | async)"
          [answerGroupId]="answerGroupId$ | async"
          [answerGroupId2]="answerGroupId2$ | async"
          (isAnswersDirty)="isAnswersDirtyEvent($event)"
          (isAnswersDirty2st)="isAnswersDirty2stEvent($event)"
        >
        </answers-question>
      </ng-container>
    </ng-container>
    <mat-divider></mat-divider>
    <div class="control-panel">
      <button
        mat-raised-button
        type="submit"
        (click)="save()"
        [disabled]="questionForm.invalid || !questionForm.dirty"
        color="primary">
        Confirm
      </button>
      <div class="controls-icons">
        <mat-icon
          matRipple
          [matRippleRadius]="20"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          class="material-icons material-icons-outlined"
          [ngClass]="{'icon-disabled': canNotAddOrRemove$ | async}"
          (click)="up()">
          arrow_upward
        </mat-icon>
        <mat-icon
          matRipple
          [matRippleRadius]="20"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          class="material-icons material-icons-outlined"
          [ngClass]="{'icon-disabled': canNotAddOrRemove$ | async}"
          (click)="down()">
          arrow_downward
        </mat-icon>

        <mat-icon
          matRipple
          [matRippleRadius]="20"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          class="material-icons material-icons-outlined"
          [ngClass]="{'icon-disabled': canNotAddOrRemove$ | async}"
          (click)="addQuestionForm()">
          add_circle_outline
        </mat-icon>

        <mat-icon
          matRipple
          [matRippleRadius]="20"
          [matRippleUnbounded]="true"
          [matRippleCentered]="true"
          *ngIf="isRemoveDisabled$ | async"
          class="material-icons material-icons-outlined"
          [ngClass]="{'icon-disabled': canNotAddOrRemove$ | async}"
          (click)="removeQuestionForm()">
          delete_outline
        </mat-icon>
      </div>
    </div>
    <div class="error-message" *ngIf="(questionForm.dirty) && roundHelperService.roundTryToSave">
      Please, confirm the question before saving the game
    </div>
  </form>
</div>

<ng-template #createAnswersGroup let-answersKey="answersKey" let-groupKey="groupKey">
  <div class="group-warning">
    Do you want to create group based on the current answers?
    <button
      (click)="createNewFromAnswers(
        answersKey,
        groupKey
      )"
      mat-raised-button
      color="primary">
      Create New
    </button>
  </div>
</ng-template>


<ng-template #createOrEditAnswersGroup let-answersKey="answersKey" let-groupKey="groupKey">
  <div class="group-warning">
    The answers do not match the selected answer group. Do you want to create a new group or edit the current one based on the current answers?
    <div class="answer-action">
      <button
        (click)="createNewFromAnswers(
          answersKey,
          groupKey
        )"
        mat-raised-button
        color="primary">
        Create New
      </button>
      <button
        (click)="editSelectedFromAnswers(
          answersKey,
          groupKey
        )"
        mat-raised-button
        color="primary">
        Edit Selected
      </button>
    </div>
  </div>
</ng-template>
