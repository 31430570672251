import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    Subject,
    catchError,
    lastValueFrom,
    takeUntil,
    tap,
    throwError,
    switchMap, of, Observable
} from "rxjs";
import {CurrentLocationService} from "../../../core/services/current-location.service";
import {TextService} from "../../../core/services/text.service";
import {MatDialog} from "@angular/material/dialog";
import {CreateVendorModalComponent} from "../_common-modal-components/create-vendor-modal/create-vendor-modal.component";
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { AnalyticsService } from "../../../core/services/analytics.service";
import { AddNewLocaleComponent } from "../_common-modal-components/add-new-locale/add-new-locale.component";
import { DialogService } from "../_base-component/dialog/dialog.service";
import { AdminsService } from "../../../core/services/admins.service";
import { NewRoundTypeSelectModalComponent } from 'src/app/new-round-wizzard/new-round-type-select-modal/new-round-type-select-modal.component';
import { CreateGameFromCsvModalComponent } from "../_common-modal-components/create-game-from-csv-modal/create-game-from-csv-modal.component";
import { RoundService } from "../../../core/services/round.service";
import { HeaderButtonsEnum } from "../../Enums/HeaderButtonsEnum";
import { UserDetailsModel } from "../../models/UserDetailsModel";
import { ProfileService } from "../../../core/services/profile.service";
import { HelpPopupComponent } from "../_common-modal-components/help-popup/help-popup.component";
import { CreateStreakComponent } from "../_streak/create-streak/create-streak.component";
import { DiffReportComponent } from "../_common-modal-components/diff-report/diff-report.component";
import { filter } from "rxjs/operators";
import { CreateStreakQuestionComponent } from "../_streak/create-streak-question/create-streak-question.component";
import { CreateStreaksLeaderboardComponent } from "../_streak/create-streaks-leaderboard/create-streaks-leaderboard.component";
import {
  SelectLeaderboardTypeModalComponent
} from "../_common-modal-components/create-leaderboard/select-leaderboard-type-modal/select-leaderboard-type-modal.component";

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  @Input() isAdmin: boolean;

  /**
   * Current page name
   */
  currentPage: string;

  /**
   * Current chanter name
   */
  currentChapter: string;

  currentLocation: string;

  headerButtonsEnum = HeaderButtonsEnum;

  userDetails$: Observable<UserDetailsModel> = this.profileService.currentUser$;

  private unsubscribe$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.currentLocationService.getCurrentChapterName()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(chapterName => {
        this.currentChapter = chapterName.length > 3 ? chapterName : chapterName.toUpperCase();
      })

    this.currentLocationService.getCurrentPage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(pagePath => {
        this.currentLocation = pagePath;
        const currentPage = this.textService.createNameWithSpaces(pagePath);

        this.currentPage = this.textService.capitalizeFirstLetterInEachWord(currentPage);
      })
  }

  constructor(
    private currentLocationService: CurrentLocationService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private analyticsService: AnalyticsService,
    private dialogService: DialogService,
    private adminsService: AdminsService,
    private roundService: RoundService,
    private profileService: ProfileService,
    public textService: TextService,
  ) {

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createGame() {
    this.dialog.open(NewRoundTypeSelectModalComponent, {
      width: '660px',
      panelClass:['prize-builder'],
    })
  }

  createQuestion() {
    this.dialog
      .open(CreateStreakQuestionComponent,{
        maxHeight:'860px',
        width:'640px',
        height:'90%',
        panelClass:['prize-builder'],
        autoFocus: false
      })
  }

  openHelpPopup() {
    this.dialog.open(HelpPopupComponent, {
      maxHeight:'470px',
      width:'750px',
      height:'90%',
      panelClass:['prize-builder'],
    })
  }

  async downloadAnalyticsReport() {
    await lastValueFrom(
      this.analyticsService.getPlayersRound()
        .pipe(
          tap(response => window.location.href = response.link),
          catchError(error => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          })
        )
    );
  }

   downloadDiffReport() {
     this.dialog
       .open(DiffReportComponent,{
         width: '660px',
       }).afterClosed()
       .pipe(
         takeUntil(this.unsubscribe$),
         filter(Boolean),
         tap(()=> {
         this.snackBarService.showSnackBar('Differance report downloaded');
       })).subscribe()
  }



  addLeaderbord() {
    this.dialog.open(SelectLeaderboardTypeModalComponent,
      {
        disableClose: true,
        autoFocus: false,
        width: '660px',
        panelClass:['prize-builder'],
      })

  }

  addStreakLeaderboard() {
    this.dialog.open(CreateStreaksLeaderboardComponent,
      {
        disableClose: true,
        autoFocus: false,
        width: '660px',
        height: '700px',
        panelClass:['prize-builder'],
      })
  }

  addNewVendor() {
    this.dialog
      .open(CreateVendorModalComponent,{
        maxHeight:'860px',
        width:'660px',
        height:'90%',
        panelClass:['prize-builder'],
      })
  }

  createGameFromCSV() {
    this.dialog
      .open(CreateGameFromCsvModalComponent,{
        width:'800px',
        panelClass:['prize-builder'],
      }).afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((roundId) => {
        if (roundId) {
          this.roundService.needUpdateRoundList();
          this.snackBarService.showSnackBar(`The game with ID ${roundId} was created successfully`);
        }
    })
  }

  async onClearCache() {
    await lastValueFrom(
      this.dialogService.openClearCacheConfirmationPopup()
        .pipe(
          switchMap((response) => {
            if (response) {
              return this.adminsService.clearCache()
                .pipe(tap(() => this.snackBarService.showSnackBar('Cache cleaned successfully')))
            } else {
              return of(null);
            }
          }),
          catchError((error) => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          })
        )
    );
  }

  addNewLanguage() {
    this.dialog
      .open(AddNewLocaleComponent,{
        maxHeight:'860px',
        width:'660px',
        panelClass:['prize-builder'],
      })
  }

  createStreakGame() {
    this.dialog
      .open(CreateStreakComponent,{
        height: '80%',
        maxHeight:'860px',
        autoFocus: false,
        disableClose: true,
        width:'660px',
        panelClass:['builder-with-tabs'],
        data: {}
      })
  }

  protected readonly HeaderButtonsEnum = HeaderButtonsEnum;
}
