import { FormGroup, ValidatorFn } from "@angular/forms";
import moment from 'moment';
import { Injectable } from "@angular/core";


@Injectable({providedIn: 'root'})

export class IsCloseDateAfterOpenService {
  /**
   * Check that close date is after open date
   */
  public validateDates(): ValidatorFn {
    return (formGroup: FormGroup) => {

      const startDate = formGroup.get('startDate').value;
      const endDate = formGroup.get('endDate').value;

      if (!startDate || !endDate) {
        return null;
      }
      if (moment(endDate).isBefore(startDate)) {
        return { datesOrderWrong: true };
      }
      return null;
    };
  }
}
