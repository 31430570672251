<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="close()">close</mat-icon>
        <h2 class="popup-header">{{ this.currentTab ? 'Edit Tab' : 'Create Tab' }}</h2>
      </div>
    </div>
  </div>
  <div class="builder-body info-popup">
    <div class="builder-content">
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="changeTabIndex($event)">
        <mat-tab
          *ngFor="let locale of locales"
          [label]="locale">
          <form [formGroup]="formGroup">
            <div [formGroupName]="locale" class="form-container">
              <div class="field-wrapper">
                <h4>Tab name</h4>
                <custom-input
                  formControlName="name"
                  placeholder="Enter tab name">
                </custom-input>
              </div>
              <div class="field-wrapper" *ngIf="locale === 'Default'">
                <h4>Tab description</h4>
                <custom-input
                  formControlName="description"
                  placeholder="Enter tab description">
                </custom-input>
              </div>
              <div class="field-wrapper" *ngIf="(selectedContentTypeValue$ | async) !== 3">
                <h4>Tab title</h4>
                <custom-input
                  formControlName="title"
                  placeholder="Enter tab title">
                </custom-input>
              </div>
              <div>
                <h4>Content</h4>
                <mat-radio-group
                  color="primary"
                  aria-label="Select content type" (change)="onSelectContentType($event)"
                  [value]="selectedContentTypeValue$ | async">
                  <mat-radio-button [value]="1">Default HTML</mat-radio-button>
                  <mat-radio-button [value]="2">Accordion</mat-radio-button>
                  <mat-radio-button [value]="3">Slider</mat-radio-button>
                </mat-radio-group>
                <html-editor-translations
                  *ngIf="(selectedContentTypeValue$ | async) === 1"
                  [editorFormControl]="getContentControl(locale)"></html-editor-translations>
                <ng-container *ngIf="(selectedContentTypeValue$ | async) === 2">
                  <form [formGroup]="jsonForm">
                    <div [formGroupName]="locales[selectedTabIndex]">
                      <ng-container formArrayName="items">
                        <div class="item-container"
                             *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
                          <custom-input
                            [placeholder]="'label text'"
                            [formControl]="item.get('title')">
                          </custom-input>
                          <html-editor-translations [editorFormControl]="item.get('content')"></html-editor-translations>
                          <div class="form-control-button-container">
                            <button
                              class="regular-button secondary-button delete"
                              mat-flat-button
                              color="primary"
                              (click)="removeItem(i, locale)"
                            >Delete</button>
                          </div>
                          <div class="divider"></div>
                        </div>
                      </ng-container>
                    </div>
                  </form>
                  <div class="form-control-button-container">
                    <button
                      class="regular-button secondary-button"
                      mat-flat-button
                      color="primary"
                      (click)="currentTab ? addItemForLocale({title: '', content: null}, locale) : addItem({title: '', content: null})"
                    >Add Question</button>
                  </div>
                </ng-container>

                <ng-container *ngIf="(selectedContentTypeValue$ | async) === 3">
                  <form [formGroup]="slidesForm">
                    <div [formGroupName]="locales[selectedTabIndex]">
                      <ng-container formArrayName="slides">
                        <div class="item-container"
                             *ngFor="let item of slides.controls; let i = index" [formGroupName]="i">
                          <custom-input
                            [placeholder]="'Enter slide title'"
                            [formControl]="item.get('title')">
                          </custom-input>
                          <html-editor-translations
                            [placeholder]="'Enter slide description'"
                            [editorFormControl]="item.get('description')"
                            [maxContentLength]="maxLengthForSliderContent"
                          ></html-editor-translations>
                          <div class="image-upload-form-field">
                            <div *ngIf="item.get('imageUrl')">
                              <drag-and-drop-file-form
                                formControlName="imageUrl"
                                [tooltipText]="tooltipTextIcon"
                                (imageUrlChange)="item?.get('imageUrl').patchValue($event)"
                                [url]="item?.get('imageUrl')?.value">
                                Slide Image
                              </drag-and-drop-file-form>
                            </div>
                          </div>
                            <div class="form-control-button-container">
                              <button
                                class="regular-button secondary-button delete"
                                mat-flat-button
                                color="primary"
                                (click)="removeSlide(i, locale)"
                              >Delete</button>
                           </div>
                          <div class="divider"></div>
                        </div>
                      </ng-container>
                    </div>
                  </form>
                  <div class="form-control-button-container">
                    <button
                      class="regular-button secondary-button"
                      mat-flat-button
                      color="primary"
                      [disabled]="slides.controls.length >= 5"
                      (click)="currentTab ? addSlideForLocale(mockSlide, locale) : addSlide(mockSlide)"
                    >Add Slide</button>
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
        <div class="buttons-container">
            <button mat-raised-button color="primary" [disabled]="this.currentTab ? !isFormDisabledForEdit() : !isFormDisabled()" (click)="onSubmit()">Save</button>
            <button mat-raised-button (click)="close()">Cancel</button>
        </div>
    </div>
  </div>
</div>
