import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "../../../../core/services/snack-bar.service";
import { DialogService } from "../../_base-component/dialog/dialog.service";
import { BehaviorSubject, catchError, lastValueFrom, map, of, Subject } from "rxjs";
import { LocalizationService } from "../../../../core/services/localization.service";
import { FormArray, FormGroup, NonNullableFormBuilder } from "@angular/forms";
import { LocalesEntityEnum } from "../../../Enums/LocalesEntityEnum";
import { takeUntil, tap } from "rxjs/operators";
import { LocaleItem } from "../../../models/LocalesResponseModel";
import { CustomLeaderboardService } from "../../../../core/services/custom-leaderboard.service";
import { APP_DATA } from "../../../../general.app.config";

@Component({
  selector: 'leaderboard-translation-modal',
  templateUrl: './leaderboard-translation-modal.component.html',
  styleUrls: ['./leaderboard-translation-modal.component.scss']
})
export class LeaderboardTranslationModalComponent implements OnDestroy{

  leaderboardDataFormGroup: FormGroup;

  locales = [];

  isLoaded$ = new BehaviorSubject(false);

    appData = APP_DATA;


    private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<LeaderboardTranslationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
    private localizationService: LocalizationService,
    private fb: NonNullableFormBuilder,
    private customLeaderboardService: CustomLeaderboardService
  ) {
    this.prepareLocaleData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async prepareLocaleData() {
    await lastValueFrom(this.localizationService.vendorLocales$.pipe(
      map((vendorLocale => {
        this.leaderboardDataFormGroup = this.fb.group({
          items: this.fb.array([]),
        });
        this.locales = this.localizationService.prepareVendorLocales(vendorLocale);
        this.createLocaleObj(this.data, vendorLocale);
        this.isLoaded$.next(true);
      }))
    ))
  }

  get formArray() {
    return this.leaderboardDataFormGroup.get('items') as FormArray;
  }


  createLocaleObj(entityLocaleSetting, vendorLocale) {
    if (!this.formArray.value.some(item => item.id === entityLocaleSetting.id)) {
      const itemFormGroup = this.localizationService.prepareLocaleObject(entityLocaleSetting, LocalesEntityEnum.LEADERBOARD, vendorLocale, 20);
      this.formArray.push(itemFormGroup);
    }
  }

  getColumns() {
    return this.localizationService.getLocalizationTableColumn(this.locales);
  }

  async onClosePrizeWizard() {
    if (this.leaderboardDataFormGroup.dirty) {
      await lastValueFrom(
        this.dialogService.open(
          {
            dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
            labelOk: 'Yes',
            labelNo: 'No'
          }
        ).pipe(
          tap((response) => {
            if (response) {
              this.dialogRef.close(true)
            }
          })
        )
      )
    } else {
      this.dialogRef.close(true);
    }
  }

  saveLocalization() {
    const {items, editedItems} = this.localizationService.createLocaleRequestObject(this.formArray.at(0), this.locales);

    this.isLoaded$.next(false);
    const request$ = items.length ?
      this.localizationService.bulkSaveLocale({items}) :
      this.localizationService.bulkEditLocale({items: editedItems});

      request$
        .pipe(
          takeUntil(this.unsubscribe$),
          tap(response => {
            if (items.length) {
              this.updateFormValue(response as LocaleItem[], this.formArray.at(0) as FormGroup);
            }
            this.customLeaderboardService.isNeedUpdateLeaderboardsList$.next(true);
            this.isLoaded$.next(true);
            this.leaderboardDataFormGroup.markAsPristine();
            this.snackBarService.showSnackBar('Translations saved successfully');
          }),
          catchError(error => {
            this.isLoaded$.next(true);
            this.snackBarService.showSnackBar(error.error.message, true);
            return of(error);
          }),
        ).subscribe();
  }

  updateFormValue(savedLocales: LocaleItem[], form: FormGroup) {
    savedLocales.forEach(locale => form.get(`${locale.locale.i18n}Id`).patchValue(locale.id));
  }
}
