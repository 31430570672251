import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, of, startWith, switchMap, tap, throwError } from 'rxjs';
import { RoundClassEnum } from 'src/app/common/Enums/RoundClassEnum';
import { DateFormats } from 'src/app/common/Enums/date-formats';
import { DialogService } from 'src/app/common/components/_base-component/dialog/dialog.service';
import { EventDetailsModel } from 'src/app/common/models/EventDetailsModel';
import { EventsService } from 'src/app/core/services/events.service';
import { RoundService } from 'src/app/core/services/round.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { SportEventIdValidator } from 'src/app/new-round-wizzard/event-selector-validator';
import { APP_DATA } from 'src/app/general.app.config';
import { RoundHelperService } from '../round-helper.service';
import { CreateEventModalComponent } from 'src/app/common/components/_common-modal-components/create-event-modal/create-event-modal.component';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';

@Component({
  selector: 'new-round-type-select-modal',
  templateUrl: './new-round-type-select-modal.component.html',
  styleUrls: ['./new-round-type-select-modal.component.scss'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'event-panel-class' }
    }
  ]
})
export class NewRoundTypeSelectModalComponent {

  roundClassForm: FormGroup;

  roundClasses$ = new BehaviorSubject<{ displayValue: string, value: RoundClassEnum }[]>(null);

  dateFormats = DateFormats;

  appData = APP_DATA;

  sportEvents$: Observable<EventDetailsModel[]>;

  constructor(
    public dialogRef: MatDialogRef<NewRoundTypeSelectModalComponent>,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    private eventsService: EventsService,
    public roundService: RoundService,
    private sportEventIdValidator: SportEventIdValidator,
    public roundHelperService: RoundHelperService,
    @Inject(MAT_DIALOG_DATA) public data: { isNew: boolean },
  ) {
    this.roundClasses$.next([
      {
        displayValue: 'Single event',
        value: RoundClassEnum.Single
      },
      {
        displayValue: 'Multi event',
        value: RoundClassEnum.Multi
      }
    ])

    this.eventsService.needUpdateEventList();
    this.sportEvents$ = this.eventsService.getEventListStatus()
      .pipe(
        switchMap(() => this.eventsService.getEventsList()),
        map(eventsList => eventsList.records),
      );
    this.buildForm();
  }

  async buildForm() {
    this.roundClassForm = new FormGroup({
      class: new FormControl(null, [Validators.required]),
      sportEventId: new FormControl(null, [Validators.required, this.sportEventIdValidator.isSelectedEvent()]),
    });
    await lastValueFrom(
      this.roundHelperService.isMultiEventRound$.pipe(
        startWith(true),
        tap((isMultiEvent) => {
          if (isMultiEvent) {
            this.roundClassForm.get('sportEventId').removeValidators(Validators.required);
            this.roundClassForm.get('sportEventId').clearValidators();
            this.roundClassForm.get('sportEventId').updateValueAndValidity();
          } else {
            this.roundClassForm.get('sportEventId').setValidators([Validators.required, this.sportEventIdValidator.isSelectedEvent()]);
            this.roundClassForm.get('sportEventId').updateValueAndValidity();
          }
        })
      )
    )
  }
  onClosePrizeWizard() {
    // we need clean value if we close this modal - it is value should be undefined for next time
    this.roundHelperService.roundClass = undefined;
    this.roundHelperService.singleRoundEvent = undefined;
    this.dialogRef.close();
  }

  selectRoundType() {
    this.dialogRef.close();
    this.dialogService.openRoundWizzard();
  }

  getOptionTextEvent(option) {
    return option?.name;
  }

  editEvent(event, sportEvent) {
    event.stopPropagation();
    this.openEventModel(sportEvent)
  }

  openEventModel(event?) {
    this.dialog
      .open(CreateEventModalComponent, {
        panelClass: ['event-builder'],
        data: event,
        autoFocus: false
      });
  }

  async deleteEvent(event, sportEvent) {
    event.stopPropagation();
    await lastValueFrom(
      this.dialogService.openDeleteConfirmationPopup(`${sportEvent.name} event`)
        .pipe(
          switchMap((response) => {
            if (response) {
              return this.eventsService.deleteEventById(sportEvent.id)
                .pipe(
                  tap(() => this.eventsService.needUpdateEventList()))
            } else {
              return of(null);
            }
          }),
          catchError((error) => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          })
        )
    );
  }

  isShowError(fieldName, errorName) {
    if (!this.roundClassForm.get(fieldName).touched) return false;
    return this.roundClassForm.get(fieldName).hasError(errorName);
  }

  onClassChange(event) {
    this.roundHelperService.roundClass = event.value;
  }
}
