import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable } from 'rxjs';
import {UriConfig} from "../../app.config";
import {RoundItemDetailsModel} from "../../common/models/RoundItemDetailsModel";
import {RoundItemStatusModel} from "../../common/models/RoundItemStatusModel";
import {PlayerItemStatusModel} from "../../common/models/PlayerItemStatusModel";
import { HealthCheckResultsModel } from "../../common/models/HealthCheckResultsModel";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  getEndingSoon(body):Observable<{ records: RoundItemDetailsModel[], total: number }> {
    let params = new HttpParams()
    Object.keys(body).filter(v => body[v]).forEach(param => {
      params = params.set(param, body[param])
    });
    return this.http.get<{ records: RoundItemDetailsModel[], total: number }>(this.uriConfig.rounds + '/vendor/ending-soon',  { params });
  }

  getRoundStatistic():Observable<RoundItemStatusModel> {
    return this.http.get<RoundItemStatusModel>(this.uriConfig.vendorAnalytic + '/games-stats');
  }

  getPlayersStatistic():Observable<PlayerItemStatusModel> {
    return this.http.get<PlayerItemStatusModel>(this.uriConfig.vendorAnalytic + '/players-stats');
  }

  getPlayersRound() {
    return this.http.get<{ link: string }>(this.uriConfig.analytics + '/detailed');
  }

  getHealthCheckResult(roundId): Observable<HealthCheckResultsModel[]> {
    return this.http.get<HealthCheckResultsModel[]>(this.uriConfig.issuesCheck + '/' + roundId);
  }
}
