import { Injectable } from '@angular/core';
import { RoundStatusEnum } from "../../../common/Enums/RoundStatusEnum";
import { StreakRoundDashboardModel } from "../../../common/models/StreakRoundDashboardModel";
import { StreakModel } from "../../../common/models/StreakModel";
import { StreaksCustomLeaderboardModel } from "../../../common/models/StreaksCustomLeaderboardModel";
import moment from "moment";
import { S3SubmissionStatusEnum } from "../../../common/Enums/S3SubmissionStatusEnum";

@Injectable({
  providedIn: 'root'
})
export class StreakHelperService {

  constructor() { }

  isRoundFinished (roundStatus) {
    return roundStatus === RoundStatusEnum.CLOSED || roundStatus === RoundStatusEnum.COMPLETED || roundStatus === RoundStatusEnum.RESULT_PROCESSING;
  }

  isRoundStarted(roundStatus) {
    return roundStatus !== RoundStatusEnum.DRAFT && roundStatus !== RoundStatusEnum.PENDING;
  }

  isDownloadAvailable(streakRound: StreakRoundDashboardModel | StreakModel) {
    return !(
      streakRound.status === RoundStatusEnum.DRAFT ||
      streakRound.status === RoundStatusEnum.PENDING
    );
  }

  getTooltipForS3Status(round: StreaksCustomLeaderboardModel | StreakModel | StreakRoundDashboardModel): string {
    const { outcomeReportStatus, outcomeReportSentAt } = round;
    const statusMessage = outcomeReportStatus === S3SubmissionStatusEnum.SUCCEED ?
      'S3 Upload Successful' : 'S3 Upload Unsuccessful';
    return `${statusMessage}: ${moment.utc(outcomeReportSentAt).local().format('D MMM YYYY, HH:mm')}`;
  }
}
